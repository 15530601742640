@use 'src/assets/scss/core/variables' as var;
@use 'src/assets/scss/core/mixins' as mix;

.root {
  @include mix.media(
    (
      1360: (
        width: 100%,
      ),
      1280: (
        top: mix.vw(60),
      ),
      md: (
        top: 0,
        width: 100%,
        height: 100dvh,
      ),
    )
  );

  position: absolute;
  z-index: 22;
  top: 60px;

  left: 50%;
  transform: translateX(-50%);

  width: 100%;
  max-width: 1296px;
}

.wrapper {
  @include mix.media(
    (
      h-max-836: (),
      1280: (
        padding: mix.vw(24) mix.vw(40) mix.vw(30),
      ),
      md: (
        max-height: unset,
        height: 100%,
        padding: 0 24px 30px,
      ),
      sm: (
        padding: 0 0 20px,
        height: 100dvh,
      ),
    )
  );

  overflow: hidden;

  box-sizing: border-box;
  padding: 32px 0;

  background: #fff;
}

.manyLinks {
  @include mix.media(
    (
      1280: (
        padding-left: mix.vw(40),
        padding-right: mix.vw(10),
      ),
      h-max-810: (
        height: calc(100vh - 170px),
      ),
      md: (
        max-height: unset,
        padding-left: 0,
        padding-right: 0,
      ),
    )
  );

  padding-left: 32px;
  padding-right: 32px;

  :global(.ScrollbarsCustom-Track) {
    @include mix.media(
      (
        md: (
          display: none,
        ),
      )
    );
  }
}

.solutions {
  @include mix.media(
    (
      1280: (
        height: mix.vw(628),
      ),
      h-max-716: (
        height: calc(100vh - 128px),
        max-height: 100%,
      ),
      md: (
        height: calc(100vh - 104px),
      ),
      sm: (
        height: calc(100dvh - 116px),
      ),
    )
  );

  height: 628px;
  max-height: 628px;
}

.staticHeight {
  max-height: 652px;

  @include mix.media(
    (
      1280: (
        max-height: calc(100vh - mix.vw(170)),
      ),
      h-max-610: (
        height: calc(100vh - 170px),
      ),
      md: (
        max-height: 100%,
        padding: 0,
        height: 100dvh,
        overflow: unset,
      ),
    )
  );
}

.listWrapper {
  height: 100%;
  @include mix.CustomScrollBarDark;
  padding: 0 32px;
  max-height: 622px;
  box-sizing: border-box;

  @include mix.media(
    (
      1280: (
        padding: 0 0 mix.vw(10),
        max-height: unset,
      ),
      md: (
        padding: 0,
        height: calc(100% - 80px),
      ),
    )
  );
}

.modal.layout {
  z-index: 21;
}

.modal {
  scrollbar-width: none;
  z-index: 21;

  @include mix.media(
    (
      md: (
        z-index: 32,
      ),
      sm: (),
    )
  );

  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.overlay {
  box-shadow: 0 4px 32px 0 rgb(34 34 34 / 5%), 0 0 8px 0 rgb(34 34 34 / 3%);
}

.info {
  @include mix.media(
    (
      md: (
        flex-direction: column,
      ),
    )
  );

  display: flex;

  &Grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 16px;
    column-gap: 32px;

    @include mix.media(
      (
        1280: (
          row-gap: mix.vw(16),
          column-gap: mix.vw(32),
        ),
        md: (
          display: grid,
          align-items: start,
          grid-template-columns: 1fr,
          gap: 0,
          grid-auto-rows: min-content,
          overflow: auto,
        ),
        sm: (
          grid-template-columns: 1fr,
        ),
      )
    );
  }

  &Links {
    @include mix.media(
      (
        1280: (
          max-height: mix.vw(524),
          width: mix.vw(800),
        ),
        md: (
          max-height: unset,
          width: 100%,
          height: 100%,
        ),
        sm: (),
      )
    );

    scrollbar-width: none;

    width: 800px;
    max-height: 524px;
    margin-left: auto;

    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .scrollbar {
    :global(.ScrollbarsCustom-TrackY) {
      top: 0 !important;
      right: -18px !important;

      width: 4px !important;
      height: 100% !important;

      background: transparent !important;
      border-radius: 100px !important;

      @include mix.media(
        (
          md: (
            display: none,
          ),
        )
      );
    }

    :global(.ScrollbarsCustom-ThumbY) {
      width: 4px !important;
      background: #d3d4d6 !important;
      border-radius: 100px !important;
    }

    :global(.ScrollbarsCustom-Wrapper) {
      inset: 0 !important;
    }

    :global(.ScrollbarsCustom-Scroller) {
      scrollbar-width: none;

      -ms-overflow-style: none;

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
}

.back {
  @include mix.TextCaptionSemibold;

  display: flex;
  gap: 4px;
  align-items: center;
  justify-content: flex-start;

  min-width: 130px;
  margin-right: auto;

  text-transform: none;
  letter-spacing: normal;

  @include mix.media(
    (
      sm: (
        min-width: 109px,
      ),
    )
  );

  svg {
    width: 16px;
    height: 16px;
  }
}

.minHeight {
  @include mix.media(
    (
      1280: (
        min-height: mix.vw(228),
      ),
      md: (
        min-height: unset,
      ),
    )
  );

  min-height: 228px;
}

.modalHeader {
  position: relative;
  display: flex;
}

.modalBackButton {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.modalTitle {
  display: block;
  text-align: center;
  width: 100%;
}
