@use 'src/assets/scss/core/variables' as var;
@use 'src/assets/scss/core/mixins' as mix;

.root {
  display: flex;
  flex-direction: column;
}

.inner {
  position: relative;
}

.textarea {
  @include mix.media(
    (
      1280: (
        height: mix.vw(164),
        margin-bottom: mix.vw(8),
        padding: mix.vw(26) mix.vw(54) mix.vw(8) mix.vw(17),
        font-size: mix.vw(18),
        line-height: mix.vw(26),
        min-height: mix.vw(164),
      ),
      md: (
        height: 164px,
        margin-bottom: 8px,
        padding: 26px 54px 8px 17px,
        font-size: 18px,
        line-height: 26px,
        min-height: 164px,
      ),
    )
  );

  resize: none;

  z-index: 2;
  transform-origin: bottom right;

  box-sizing: border-box;
  width: 100%;
  min-height: 164px;
  margin-bottom: 8px;
  padding: 35px 17px 13px;

  font-family: var.$secondary-font;
  font-size: 18px;
  line-height: 26px;
  color: #212830;

  background: #f5f5f7;
  border: none;
  border-radius: 8px;
  outline: none;

  transition: background 0.2s ease-in-out;

  &::placeholder {
    color: transparent;
  }

  &:hover {
    background: #d3d4d6;

    + .name {
      background: #d3d4d6;
    }
  }

  &:focus {
    background: #f5f5f7;
    outline: 1.6px solid #07e897;

    &::placeholder {
      color: #63696e;
    }

    + .name {
      background: #f5f5f7;
    }
  }

  &:focus,
  &:not(&:placeholder-shown) {
    + .name {
      @include mix.media(
        (
          1280: (
            font-size: mix.vw(14),
            line-height: mix.vw(16),
            padding-top: mix.vw(4),
          ),
          md: (
            font-size: 14px,
            line-height: 16px,
            padding-top: 4px,
          ),
        )
      );

      padding-top: 4px;
      font-size: 14px;
      line-height: 16px;
      color: #7a7e83;
    }
  }
}

.name {
  @include mix.media(
    (
      1280: (
        font-size: mix.vw(18),
        line-height: mix.vw(26),
        left: mix.vw(16),
        padding-top: mix.vw(16),
      ),
      md: (
        font-size: 18px,
        line-height: 26px,
        left: 16px,
        padding-top: 16px,
      ),
    )
  );

  pointer-events: none;

  position: absolute;
  z-index: 5;
  top: 0;
  left: 16px;

  width: calc(100% - 32px);
  padding-top: 16px;

  font-size: 18px;
  line-height: 26px;
  color: #7a7e83;

  background-color: #f5f5f7;

  transition: color 0.3s cubic-bezier(0, 0, 0.2, 1),
    font-size 0.3s cubic-bezier(0, 0, 0.2, 1),
    max-width 0.3s cubic-bezier(0, 0, 0.2, 1),
    line-height 0.3s cubic-bezier(0, 0, 0.2, 1),
    padding-top 0.3s cubic-bezier(0, 0, 0.2, 1), background 0.2s ease-in-out;
}

.hiddenName {
  @include mix.media(
    (
      1280: (
        font-size: mix.vw(14),
        line-height: mix.vw(16),
        padding-top: mix.vw(8),
      ),
      md: (
        font-size: 14px,
        line-height: 16px,
        padding-top: 8px,
      ),
    )
  );

  pointer-events: none;

  position: absolute;

  display: block;

  padding-top: 8px;

  font-size: 14px;
  line-height: 16px;

  opacity: 0;
}

.purple {
  .textarea {
    &:focus {
      outline: 1.6px solid #5345d2;
    }
  }
}

.errorText {
  @include mix.media(
    (
      1280: (
        font-size: mix.vw(14),
        line-height: mix.vw(20),
        margin-bottom: mix.vw(8),
      ),
      md: (
        font-size: 14px,
        line-height: 20px,
        margin-bottom: 8px,
      ),
    )
  );

  display: block;

  margin-bottom: 8px;

  font-size: 14px;
  line-height: 20px;
  color: #e84e58;
  letter-spacing: 0.01em;
}

.error {
  .textarea {
    background: #f9d3d5;
    outline: none;

    + .name {
      background: #f9d3d5;
    }

    &:hover {
      background: #f4a7ab;

      + .name {
        background: #f4a7ab;
      }
    }

    &:focus {
      background: #f5f5f7;

      + .name {
        background: #f5f5f7;
      }
    }
  }
}

.hint {
  @include mix.media(
    (
      1280: (
        padding-bottom: mix.vw(8),
      ),
      md: (
        padding-bottom: 8px,
      ),
    )
  );

  cursor: default;

  position: relative;

  display: flex;
  align-items: center;

  width: 100%;
  padding-bottom: 8px;

  &Label {
    @include mix.media(
      (
        1280: (
          font-size: mix.vw(18),
          line-height: mix.vw(26),
          margin-right: mix.vw(6),
        ),
        md: (
          font-size: 18px,
          line-height: 26px,
          margin-right: 6px,
        ),
      )
    );

    margin-right: 6px;
    font-size: 18px;
    line-height: 26px;
    color: #212830;
  }

  &Text {
    @include mix.media(
      (
        1280: (
          font-size: mix.vw(16),
          line-height: mix.vw(24),
          top: mix.vw(35),
          padding: mix.vw(16),
        ),
        md: (
          font-size: 16px,
          line-height: 24px,
          top: 35px,
          padding: 16px,
        ),
      )
    );

    cursor: text;

    position: absolute;
    z-index: 4;
    top: 35px;
    right: 0;

    display: none;

    padding: 16px;

    font-size: 16px;
    line-height: 24px;
    color: #212830;

    background: #d3d4d6;
    border-radius: 8px;
    box-shadow: 0 0 8px rgb(45 60 100 / 3%), 0 4px 32px rgb(45 60 100 / 5%);
  }

  svg {
    color: #a6a9ac;
    transition: 0.2s color ease-in-out;
  }

  &:hover {
    .hintText {
      display: block;
    }

    svg {
      color: #212830;
    }
  }
}

.reqText {
  @include mix.media(
    (
      1280: (
        font-size: mix.vw(14),
        line-height: mix.vw(20),
        padding: mix.vw(7),
        margin-left: mix.vw(5),
      ),
      md: (
        font-size: 14px,
        line-height: 20px,
        padding: 7px,
        margin-left: 5px,
      ),
    )
  );

  position: absolute;
  top: 0;
  right: 0;

  margin-left: 5px;
  padding: 7px;

  font-size: 14px;
  line-height: 20px;
  color: #a6a9ac;
}

.bottom {
  display: flex;
  justify-content: space-between;
}

.length {
  @include mix.media(
    (
      1280: (
        font-size: mix.vw(14),
        line-height: mix.vw(20),
      ),
      md: (
        font-size: 14px,
        line-height: 20px,
      ),
    )
  );

  margin-left: auto;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.01em;
}

.top {
  position: relative;

  &Visible {
    @include mix.media(
      (
        1280: (
          height: mix.vw(34),
        ),
        md: (
          height: 34px,
        ),
      )
    );

    height: 34px;
  }
}
