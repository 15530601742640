@use 'src/assets/scss/core/variables' as var;
@use 'src/assets/scss/core/mixins' as mix;
@use '@sbercloud/figma-tokens-web/build/scss/components/styles-tokens-element'
  as ste;

.root {
  height: 100%;

  @include mix.media(
    (
      1269: (
        padding-top: 36px,
      ),
    )
  );
}

.content {
  @include mix.media(
    (
      1269: (
        gap: 40px,
        grid-template-columns: 100%,
        height: 100%,
      ),
      md: (
        gap: 22px,
      ),
      sm: (
        height: calc(100% - 80px),
      ),
    )
  );

  display: flex;
  gap: 72px;
  height: 100%;
}

.menu {
  @include mix.media(
    (
      md: (
        display: none,
      ),
    )
  );

  width: 240px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding-top: 4px;

  &Item {
    color: ste.$sys-neutral-text-main;
    cursor: pointer;

    display: flex;
    gap: 4px;
    align-items: center;

    box-sizing: border-box;
    max-width: 240px;
    padding: 8px 10px 8px 12px;

    border-left: 1px solid var.$graphite-10;

    transition: color 0.3s ease-in-out;

    &:hover,
    &Active {
      padding-left: 11px;
      background: var.$green-main-50;
      border-left: 2px solid var.$green-main-100;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &ItemImg {
    width: 20px;
    height: 20px;

    @include mix.media(
      (
        1280: (
          width: mix.vw(20),
          height: mix.vw(20),
        ),
        md: (
          width: 20px,
          height: 20px,
        ),
      )
    );
  }

  &Section {
    height: 100%;
    display: flex;
    flex-direction: column;
    &.last {
      // padding-bottom: 60px;

      @include mix.media(
        (
          1280: (
            padding-bottom: mix.vw(60),
          ),
        )
      );
    }
  }

  &Category {
    @include mix.TextB1Semibold;

    margin-bottom: 16px;

    @include mix.media(
      (
        1280: (
          margin-bottom: mix.vw(16),
        ),
      )
    );
  }
}

.description {
  display: inline-block;

  @include mix.TextB1Regular;

  @include mix.media(
    (
      1280: (
        margin: 0 0 mix.vw(16),
      ),
      md: (
        margin: 0 0 16px,
      ),
    )
  );

  margin: 0 0 16px;

  :global(.categoryDescription) {
    @include mix.TextB1Semibold;
  }
}

.blockHeader {
  padding-block: 32px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 24px;
  height: 40px;

  @include mix.media(
    (
      1269: (
        padding-block: 0 16px,
      ),
    )
  );

  &Small {
    padding-top: 0;
  }
}

.categorySectionTitle {
  color: ste.$sys-neutral-text-main;
}

.title {
  width: 100%;
  color: ste.$sys-neutral-text-main;

  br {
    display: none;
  }
}

.contentWrapper {
  flex-basis: 100%;
}

.right {
  @include mix.media(
    (
      1269: (
        padding: 32px 0 24px,
        height: 100%,
        margin-left: 0,
        width: 100%,
        overflow: unset #{'!important'},
        max-height: unset,
      ),
    )
  );
}

.block {
  &Grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 32px;

    margin-bottom: 24px;

    @include mix.media(
      (
        1269: (
          grid-template-columns: repeat(2, 1fr),
          gap: 24px,
        ),
        sm: (
          grid-template-columns: 1fr,
          gap: 20px,
        ),
      )
    );

    &Platforms {
      grid-template-columns: repeat(2, 1fr);

      @include mix.media(
        (
          sm: (
            grid-template-columns: 1fr,
          ),
        )
      );
    }
  }

  position: relative;

  // @include mix.media(
  //   (
  //     1269: (
  //       padding: 24px 0,
  //     ),
  //   )
  // );

  &.unactive {
    display: none;
    @include mix.media(
      (
        1269: (
          display: block,
        ),
      )
    );
  }
}

.scrollbar {
  width: 100%;

  :global(.ScrollbarsCustom-TrackY) {
    @include mix.media(
      (
        1280: (
          right: '-#{mix.vw(20)} !important',
          width: '#{mix.vw(4)} !important',
        ),
        md: (
          height: 'calc(100% - 100px) !important',
          right: '-20px !important',
          width: '4px !important',
        ),
      )
    );

    top: 0 !important;
    right: -20px !important;

    width: 4px !important;
    height: 100% !important;

    background: transparent !important;
    border-left: 1px solid #bcbfc1 !important;
    border-radius: 0 !important;
  }

  :global(.ScrollbarsCustom-Content) {
    border-right: none !important;
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  :global(.ScrollbarsCustom-ThumbY) {
    width: 4px !important;
    background: #a6a9ac !important;
    border-radius: 0 !important;

    @include mix.media(
      (
        1280: (
          width: '#{mix.vw(4)} !important',
        ),
        md: (
          width: '4px !important',
        ),
      )
    );
  }

  :global(.ScrollbarsCustom-Scroller) {
    scrollbar-width: none;

    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  :global(.ScrollbarsCustom-Wrapper) {
    inset: 0 !important;
  }

  &Menu {
    :global(.ScrollbarsCustom-TrackY) {
      right: 12px !important;
      max-height: 100%;

      @include mix.media(
        (
          1280: (
            right: '#{mix.vw(12)} !important',
          ),
          md: (
            right: '12px !important',
          ),
        )
      );
    }
  }
}

.searchIcon,
.clearIcon {
  @include mix.media(
    (
      1280: (
        width: mix.vw(14),
        height: mix.vw(14),
      ),
      md: (
        width: 14px,
        height: 14px,
      ),
    )
  );

  position: absolute;
  top: 50%;
  transform: translateY(-50%);

  width: 14px;
  height: 14px;
}

.searchIcon {
  @include mix.media(
    (
      1280: (
        left: mix.vw(10),
      ),
      md: (
        left: 10px,
      ),
    )
  );

  left: 10px;
}

.clearIcon {
  @include mix.media(
    (
      1280: (
        right: mix.vw(10),
      ),
      md: (
        right: 10px,
      ),
    )
  );

  cursor: pointer;
  right: 10px;
  color: #212830;

  &:hover {
    color: #06bd7b;
  }
}

.skeleton {
  &MenuItem {
    box-sizing: border-box;
    max-width: 240px;
    padding: 10px 0 10px 12px;
    border-left: 1px solid var.$graphite-10;

    @include mix.media(
      (
        1280: (
          max-width: mix.vw(240),
          padding: mix.vw(10) mix.vw(12),
        ),
      )
    );

    span {
      border-radius: 0;
    }
  }

  &Card {
    display: flex;
    padding: 20px;
    background: var.$page-background;
    box-shadow: 0 0 8px 0 rgba(34, 34, 34, 0.03),
      0 4px 32px 0 rgba(34, 34, 34, 0.05);
    @include mix.media(
      (
        1280: (
          padding: mix.vw(16) mix.vw(8),
        ),
        md: (
          padding: 16px 8px,
        ),
        sm: (),
      )
    );
  }

  &Img {
    width: 32px;
    height: 32px;
    margin-right: 16px;
    border-radius: 0;

    @include mix.media(
      (
        1280: (
          width: mix.vw(32),
          height: mix.vw(32),
          margin-right: mix.vw(16),
        ),
        md: (
          width: 32px,
          height: 32px,
          margin-right: 16px,
        ),
      )
    );
  }

  &Title {
    width: 340px;
    height: 32px;
    margin-bottom: 8px;
    border-radius: 0;

    @include mix.media(
      (
        1280: (
          height: mix.vw(32),
          width: mix.vw(200),
          margin-bottom: mix.vw(8),
        ),
        md: (
          height: 16px,
          margin-bottom: 8px,
          width: mix.vw(400),
        ),
        sm: (
          width: mix.vw(900),
        ),
      )
    );
  }

  &Desc {
    height: 48px;
    border-radius: 0;

    @include mix.media(
      (
        1280: (
          height: mix.vw(48),
        ),
        md: (
          height: 48px,
        ),
      )
    );
  }

  &PlatformFilter {
    margin-bottom: 24px;
    max-width: 240px;
    height: 48px;
    border-radius: 0;
  }

  &ShowMore {
    width: 130px;
    height: 40px;
    border-radius: 0;
  }
}

.searchAll {
  display: inline-block;
  color: ste.$sys-neutral-accent-default;

  &:hover {
    .link {
      color: var.$green-main-100;
    }
  }

  .link {
    display: inline-block;
    pointer-events: visible !important;
    color: inherit;
    text-decoration: underline;
    text-decoration-skip-ink: none;
    word-break: break-word;

    transition: color 0.3s ease-in-out;

    width: fit-content;
  }

  @include mix.media(
    (
      1269: (
        margin-top: 8px,
      ),
    )
  );
}

.scrollbarMenu {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.skeleton {
  &ProductCard {
    display: grid;
    grid-template-columns: (40px auto);
    gap: 12px;
  }
}

.tablet {
  &AllProductsButton {
    margin-top: 40px;
  }
}

.search {
  &Wrapper {
    margin: 3px;

    @include mix.media(
      (
        1269: (
          margin-bottom: 16px,
        ),
      )
    );
  }
}

.search.border {
  border: 1px solid ste.$sys-neutral-decor-default;
}

.platformSelectWrapper {
  margin: 3px 3px 16px 3px;
}

.allProductsButton {
  padding-top: 16px;
}

.tabBar {
  margin-bottom: 24px;
}

.notFound {
  &Title {
    padding-block: 32px 0;
  }

  &SearchAll {
    margin-top: 0;
  }
}

.additionalLinks {
  display: flex;
  flex-direction: column;

  @include mix.media(
    (
      md: (
        margin-top: 10px,
      ),
    )
  );
}

.categoryList {
  flex: 1 1 auto;
  overflow: hidden;
  min-height: 120px;
  flex-grow: 0;
}
