@use 'src/assets/scss/core/variables' as var;
@use 'src/assets/scss/core/mixins' as mix;

.root {
  @include mix.TextB1Regular;

  display: inline-flex;
  gap: 5px;
  align-items: center;

  color: var.$graphite-100;

  transition: color 0.3s ease-in-out;

  &:hover {
    color: var.$green-main-100;
  }

  &:focus-visible {
    outline: 2px solid var.$information-100;
  }
}

.disabled {
  pointer-events: none;
  user-select: none;
  opacity: 0.3;

  &:focus {
    outline: 2px;
  }
}

.withIcon {
  text-decoration: none;
}

.icon {
  @include mix.media(
    (
      1280: (
        width: mix.vw(20),
        height: mix.vw(20),
      ),
      md: (
        width: 20px,
        height: 20px,
      ),
    )
  );

  width: 20px;
  height: 20px;
  color: var.$green-main-100;
}

.readOnly {
  pointer-events: none;
  user-select: none;
  text-decoration: none;
}

.white {
  color: var.$gray-0;

  &:hover {
    color: var.$green-main-100;
  }

  .icon {
    color: var.$gray-0;
  }
}

.additional {
  &:hover {
    color: var.$graphite-hover;
  }

  .icon {
    color: var.$graphite-100;
  }
}
