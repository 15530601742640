@use 'src/assets/scss/core/variables' as var;
@use 'src/assets/scss/core/mixins' as mix;
@use '@sbercloud/figma-tokens-web/build/scss/components/styles-tokens-element'
  as ste;

.root {
  @include mix.media(
    (
      sm: (
        padding: 16px 24px,
        gap: 6px,
        flex-direction: column,
      ),
    )
  );

  scrollbar-width: none;

  position: absolute;
  z-index: 5;
  top: 56px;
  left: 0;

  flex-direction: column;
  gap: 6px;

  box-sizing: border-box;
  width: 100%;
  padding: 16px 40px;

  background: #fff;

  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }

  &.visible {
    display: flex;
  }
}

.modal {
  scrollbar-width: none;
  z-index: 20;

  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.overlay {
  background: rgb(34 34 34 / 80%);
}

.col {
  @include mix.media(
    (
      1280: (
        gap: mix.vw(16),
        padding-bottom: mix.vw(16),
      ),
      md: (
        gap: 16px,
        padding-bottom: 16px,
      ),
    )
  );
  display: flex;
  flex-direction: column;
  gap: 16px;

  padding-bottom: 16px;
}

.menu {
  padding: 8px;
  display: flex;
  gap: 2px;
  align-items: center;
  justify-content: space-between;

  transition: color 0.3s ease-in-out;

  &:hover {
    color: #06bd7b;
  }

  &Text {
    color: ste.$sys-neutral-text-main;
  }

  &Icon {
    color: ste.$sys-neutral-text-light;
  }
}

.button {
  padding: 8px 16px;
  width: 100%;
}

.userAuth {
  padding-bottom: 8px;

  border-bottom: 1px solid ste.$sys-neutral-decor-default;

  &Content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 24px;
  }
}
