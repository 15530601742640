@use 'src/assets/scss/core/variables' as var;
@use 'src/assets/scss/core/mixins' as mix;

.root {
  @include mix.TextButton;

  cursor: pointer;

  display: inline-flex;
  gap: 4px;
  align-items: center;
  justify-content: center;

  box-sizing: border-box;

  color: inherit;
  text-decoration: none;

  border: 2px solid transparent;

  transition: 0.3s ease-in-out;
  transition-property: background, color, border-color;

  @include mix.media(
    (
      1280: (
        gap: mix.vw(4),
      ),
      md: (
        gap: 4px,
      ),
    )
  );

  &:focus-visible {
    outline: 2px solid var.$information-100;
  }
}

.disabled {
  pointer-events: none;
  user-select: none;
  opacity: 0.3;

  &:focus-visible {
    outline: none;
  }
}

// prop [color]
.green {
  background: var.$green-main-100;

  &:hover {
    background: var.$green-main-hover;
  }

  &:active {
    background: var.$green-main-active;
  }

  &.disabled {
    background: var.$graphite-10;
  }
}

.yellow {
  background: var.$fluorescent-yellow-1;

  &:hover {
    background: var.$fluorescent-yellow-hover;
  }

  &:active {
    background: var.$fluorescent-yellow-active;
  }

  &.disabled {
    background: var.$fluorescent-yellow-1;
  }
}

// prop [variant]
.outline {
  background: transparent;

  &:focus-visible {
    border-color: var.$graphite-10;
  }

  &.graphite {
    border-color: var.$graphite-60;

    &:hover {
      background: var.$green-main-hover;
      border-color: var.$green-main-hover !important;
    }

    &:active {
      background: var.$green-main-active;
      border-color: var.$green-main-active !important;
    }

    &.disabled {
      background: var.$graphite-10;
      border-color: var.$graphite-10;
    }
  }

  &.green {
    color: var.$green-main-100;
    border-color: var.$green-main-100;

    &:hover,
    &:active,
    &.disabled {
      color: var.$graphite-100;
    }

    &:hover {
      background: var.$green-main-hover;
      border-color: var.$green-main-hover !important;
    }

    &:active {
      background: var.$green-main-active;
      border-color: var.$green-main-active !important;
    }

    &.disabled {
      background: var.$graphite-10;
      border-color: var.$graphite-10;
    }
  }

  &.yellow {
    color: var.$graphite-100;
    border-color: var.$fluorescent-yellow-1;

    &:hover {
      background: var.$fluorescent-yellow-hover;
      border-color: var.$fluorescent-yellow-hover !important;
    }

    &:active {
      background: var.$fluorescent-yellow-active;
      border-color: var.$fluorescent-yellow-active !important;
    }

    &.disabled {
      background: var.$fluorescent-yellow-1;
      border-color: var.$fluorescent-yellow-1;
    }
  }
}

.tertiary {
  padding: 2px !important;
  background: transparent !important;
  border: none;

  @include mix.media(
    (
      1280: (
        padding: '#{mix.vw(2)} !important',
      ),
      md: (
        padding: 2px,
      ),
    )
  );

  &:hover {
    color: var.$green-main-100;
  }

  &:active {
    color: var.$graphite-60;
  }

  &.disabled {
    color: var.$graphite-100;
    background: transparent;
  }

  &.green {
    color: var.$green-main-100;
    background: transparent !important;

    &:hover {
      color: var.$green-main-hover;
    }

    &:active {
      color: var.$green-main-active;
    }

    &.disabled {
      color: var.$graphite-10;
    }
  }
}

// prop [fullWidth=true]
.fullWidth {
  width: 100%;
}

// prop [size]
.standard {
  padding: 12px 24px;

  @include mix.media(
    (
      1280: (
        padding: mix.vw(12) mix.vw(24),
      ),
      md: (
        padding: 12px 24px,
      ),
    )
  );

  &.onlyIcon {
    padding: 12px;

    @include mix.media(
      (
        1280: (
          padding: mix.vw(12),
        ),
        md: (
          padding: 12px,
        ),
      )
    );
  }
}

.big {
  padding: 20px 32px;

  @include mix.media(
    (
      1280: (
        padding: mix.vw(20) mix.vw(32),
      ),
      md: (
        padding: 20px 32px,
      ),
    )
  );

  &.onlyIcon {
    padding: 20px;

    @include mix.media(
      (
        1280: (
          padding: mix.vw(20),
        ),
        md: (
          padding: 20px,
        ),
      )
    );
  }
}
