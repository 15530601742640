@use 'src/assets/scss/core/variables' as var;
@use 'src/assets/scss/core/mixins' as mix;

.wrapper {
  position: relative;
  width: fit-content;

  &:hover {
    .root {
      background-color: #fff;
      border-color: var.$green-main-100;
    }

    .filled {
      border-color: var.$green-main-100;
    }
  }

  &.disabled {
    .root {
      color: var.$graphite-80;
      opacity: 0.4;
      background-color: #f6f6f6;
      border-color: transparent;
    }

    .searchIcon,
    .clearIcon {
      opacity: 0.4;
    }
  }
}

.root {
  @include mix.TextB1Regular;

  box-sizing: border-box;
  width: 100%;
  min-width: 328px;
  padding: 10px 36px;

  background-color: var.$gray-80;
  border: 2px solid rgb(0 0 0 / 0%);
  outline: none;

  transition: border-color 0.3s, background-color 0.3s ease;

  @include mix.media(
    (
      1280: (
        padding: mix.vw(10) mix.vw(36),
        min-width: mix.vw(328),
      ),
      md: (
        padding: 10px 36px,
        min-width: 328px,
      ),
    )
  );

  &:focus-visible {
    border-color: #0063ff;

    &::placeholder {
      color: var.$graphite-10;
    }
  }
}

.filled {
  color: var.$graphite-100;
  background-color: #f6f6f6;
  border-color: #26d07c;

  &:focus-visible {
    border-color: #26d07c;
  }
}

.small {
  @include mix.TextB2Regular;

  padding: 6px 36px;

  &:not(.filled) {
    padding: 6px 7px 6px 36px;

    @include mix.media(
      (
        1280: (
          padding: mix.vw(6) mix.vw(7) mix.vw(6) mix.vw(36),
        ),
        md: (
          padding: 6px 7px 6px 36px,
        ),
      )
    );
  }

  @include mix.media(
    (
      1280: (
        padding: mix.vw(6) mix.vw(36),
      ),
      md: (
        padding: 6px 36px,
      ),
    )
  );
}

.searchIcon,
.clearIcon {
  cursor: pointer;

  position: absolute;
  z-index: 3;
  top: 50%;
  transform: translateY(-50%);

  transition: color 0.3s ease;
}

.searchIcon {
  left: 12px;
  width: 14px;
  height: 14px;

  @include mix.media(
    (
      1280: (
        left: mix.vw(12),
        width: mix.vw(14),
        height: mix.vw(14),
      ),
      md: (
        left: 12px,
        width: 14px,
        height: 14px,
      ),
    )
  );
}

.clearIcon {
  right: 12px;
  width: 20px;
  height: 20px;

  path {
    fill: #a6a6a6;
    transition: fill 0.3s ease-in-out;
  }

  &:hover {
    path {
      fill: var.$graphite-100;
    }
  }

  @include mix.media(
    (
      1280: (
        right: mix.vw(12),
        width: mix.vw(20),
        height: mix.vw(20),
      ),
      md: (
        right: 12px,
        width: 20px,
        height: 20px,
      ),
    )
  );
}

.withButton {
  .find {
    position: absolute;
    top: 50%;
    right: 14px;
    transform: translateY(-50%);

    @include mix.media(
      (
        1280: (
          right: mix.vw(14),
        ),
        md: (
          right: 14px,
        ),
      )
    );

    &::before {
      content: '';

      position: absolute;
      left: -6px;

      width: 1px;
      height: 24px;

      background: var.$graphite-10;

      @include mix.media(
        (
          1280: (
            height: mix.vw(24),
            width: mix.vw(1),
            left: '-#{mix.vw(6)}',
          ),
          md: (
            width: 1px,
            height: 24px,
            left: -6px,
          ),
        )
      );
    }
  }

  .clearIcon {
    right: 82px;

    @include mix.media(
      (
        1280: (
          right: mix.vw(82),
        ),
        md: (
          right: 82px,
        ),
      )
    );
  }

  .root {
    padding: 10px 96px 10px 36px;
  }
}
