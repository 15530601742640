@use '@sbercloud/figma-tokens-web/build/scss/components/styles-tokens-element'
  as ste;

@use 'src/assets/scss/core/mixins' as mix;

.root {
  cursor: pointer;

  display: flex;
  align-items: center;
  gap: 8px;

  height: 48px;

  padding: 5px 11px 5px 7px;

  background-color: ste.$sys-neutral-background1-level;

  border: 1px solid ste.$sys-neutral-decor-default;

  box-sizing: border-box;

  &:hover {
    border-color: ste.$sys-neutral-decor-hovered;
  }

  &Focus {
    @include ste.outline-var(ste.$container-focused-m);

    background-color: ste.simple-var(ste.$sys-neutral-background2-level);
    border-color: ste.simple-var(
      ste.$theme-variables,
      'sys',
      'primary',
      'accent-default'
    ) !important;
    outline-color: ste.simple-var(
      ste.$theme-variables,
      'sys',
      'primary',
      'decor-activated'
    );
  }
}

.icon {
  &Container {
    width: 36px;
    height: 36px;
    flex-shrink: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    color: ste.$sys-graphite-text-main;
    background-color: ste.$sys-graphite-decor-default;
  }

  &Chevron {
    color: ste.$sys-neutral-text-light;

    &Container {
      margin-left: auto;

      width: 24px;
      height: 24px;
    }
  }
}

.value {
  // width: 188px;

  @include mix.media(
    (
      1023: (
        // width: 100%,
      ),
    )
  );

  &Label {
    cursor: pointer;

    color: ste.$sys-neutral-text-light;
  }

  &Title {
    color: ste.$sys-neutral-text-main;
  }
}
