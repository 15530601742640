@use '/src/assets/scss/core/variables' as var;
@use '/src/assets/scss/core/mixins' as mix;

.wrapper {
  min-width: 240px;
  padding: 0 8px;
  z-index: 100;
}

.col {
  padding: 4px 0;

  border-bottom: 1px solid #dfe2ec;

  &:last-child {
    border-bottom: none;
  }
}
