@use 'src/assets/scss/core/variables' as var;
@use 'src/assets/scss/core/mixins' as mix;

.icon {
  @include mix.media(
    (
      1280: (
        width: mix.vw(20),
        height: mix.vw(20),
      ),
      md: (
        width: 20px,
        height: 20px,
      ),
    )
  );

  width: 20px;
  height: 20px;
}
