.root {
  display: block;

  width: 100%;
  margin: 0 auto;
  padding: 0;

  background: transparent;
  border: none;
}
