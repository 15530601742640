@use '@sbercloud/figma-tokens-web/build/scss/components/styles-tokens-element'
  as ste;

.buttonExit {
  &Wrapper {
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
  }
  &Text {
    color: ste.$sys-neutral-text-main;
  }
}
