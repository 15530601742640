@use 'src/assets/scss/core/variables' as var;
@use 'src/assets/scss/core/mixins' as mix;
@use '@sbercloud/figma-tokens-web/build/scss/components/styles-tokens-element'
  as ste;

.linkCard {
  position: relative;
  display: flex;
  gap: 12px;
  color: var.$graphite-100;
  text-decoration: none;
  align-items: center;
  padding: 20px;
  background: var.$page-background;
  box-shadow: 0 0 8px 0 rgba(34, 34, 34, 0.03),
    0 4px 32px 0 rgba(34, 34, 34, 0.05);

  text-decoration: none;
  transition: background 0.3s ease;

  @include mix.media(
    (
      1269: (
        padding: 24px,
      ),
    )
  );

  @include mix.media(
    (
      1269: (
        gap: 12px,
      ),

      sm: (
        flex-direction: column,
        align-items: flex-start,
      ),
    )
  );

  &Description {
    padding-right: 108px;
    flex-grow: 1;
    align-items: center;

    @include mix.media(
      (
        1269: (
          align-items: flex-start,
        ),
      )
    );
  }

  &ImageWrapper {
    position: absolute;
    top: 0;
    right: 20px;
    bottom: 0;
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;

    width: 88px;
    height: 100%;

    &:empty {
      display: none;
    }
  }

  &Image {
    max-width: 100%;
    width: 100%;
    height: auto;
  }

  &Title {
    padding-right: 20px;
    color: ste.$sys-neutral-text-main;
  }
}
