@use 'src/assets/scss/core/variables' as var;
@use 'src/assets/scss/core/mixins' as mix;
@use '@sbercloud/figma-tokens-web/build/scss/components/styles-tokens-element'
  as ste;

.root {
  @include mix.media(
    (
      1269: (
        padding: 24px 0 0,
        box-sizing: border-box,
        overflow-y: auto,
      ),
    )
  );

  height: 100%;

  &::-webkit-scrollbar-track {
    border-radius: 0;
  }

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: var.$graphite-50;
  }
}

.grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 32px;

  &.four {
    grid-template-columns: repeat(4, 1fr);
  }
}

.sectionGrid {
  display: grid;
  grid-template-columns: 100%;
  gap: 56px;

  @include mix.media(
    (
      1269: (
        gap: 32px,
      ),
    )
  );
}

.items {
  @include mix.media(
    (
      1269: (
        display: grid,
        grid-template-columns: 1fr 1fr,
        gap: 24px,
      ),
      sm: (
        grid-template-columns: 1fr,
        gap: 24px,
      ),
    )
  );
}

.header {
  @include mix.TitleH4;
  grid-column: 1 / 4;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  &.four {
    grid-column: 1 / 5;
  }
}

.title {
  color: ste.$sys-neutral-text-main;
}

.subtitle {
  display: flex;
  gap: 4px;
  align-items: center;
  color: ste.$sys-neutral-text-light;
  &Link {
    display: flex;
    gap: 4px;
    align-items: center;
    text-decoration: none;
    color: ste.$sys-neutral-text-light;
  }
}

.col {
  @include mix.media(
    (
      1269: (
        margin-bottom: 32px,
        display: flex,
        flex-direction: column,
        gap: 24px,
      ),
    )
  );

  &:last-child {
    margin-bottom: 0;
  }
}

.scrollbar {
  width: 100%;

  :global(.ScrollbarsCustom-TrackY) {
    top: 0 !important;
    right: -18px !important;

    width: 4px !important;
    height: 100% !important;

    background: transparent !important;
    border-radius: 100px !important;
  }

  :global(.ScrollbarsCustom-ThumbY) {
    width: 4px !important;
    background: #d3d4d6 !important;
    border-radius: 100px !important;
  }

  :global(.ScrollbarsCustom-Scroller) {
    scrollbar-width: none;

    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.cardEnd {
  grid-column: 1 / 4;

  @include mix.media(
    (
      md: (
        grid-column: '1 / 3',
      ),
      sm: (
        grid-column: '1 / 1',
      ),
    )
  );
}
