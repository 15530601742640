@use 'src/assets/scss/core/variables' as var;
@use 'src/assets/scss/core/mixins' as mix;
@use '@sbercloud/figma-tokens-web/build/scss/components/styles-tokens-element'
  as ste;

.root {
  position: relative;
  padding: 12px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-image: url('https://cdn.cloud.ru/backend/layout/header/platform_wrapper.png');
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: auto 100%;

  @include mix.media(
    (
      sm: (
        background-image: unset,
        flex-direction: column,
        align-items: flex-start,
        gap: 12px,
      ),
    )
  );
}

.title {
  color: ste.$sys-neutral-text-main;
}

.text {
  color: ste.$sys-neutral-text-support;
}

.description {
  padding: 0 4px;
}

.link {
  display: block;
  margin-top: auto;

  &Text {
    color: ste.$sys-neutral-accent-default;
  }

  &:before {
    content: '';
    display: block;
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
  }

  &:hover {
    .linkText {
      color: ste.$sys-neutral-text-main;
    }
  }
}
