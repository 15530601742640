@use '@sbercloud/figma-tokens-web/build/scss/components/styles-tokens-element'
  as ste;

.root {
  position: relative;
  display: flex;
  gap: 12px;

  &:hover {
    .titleText {
      color: ste.$sys-primary-accent-hovered;
    }
  }

  &:focus {
    outline: 2px solid ste.$sys-primary-accent-hovered;
  }
}

.icon {
  width: 40px;
  height: 40px;
  padding: 8px;
  background: ste.$sys-graphite-decor-default;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  box-sizing: border-box;

  svg,
  img {
    width: 24px;
    height: 24px;
  }
}

.header {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 8px;
}

.link {
  color: unset;
  text-decoration: none;

  &:after {
    content: '';
    position: absolute;
    inset: 0;
  }
}

.titleText {
  color: ste.$sys-neutral-text-main;
  transition: color 0.3s ease;
}

.description {
  color: ste.$sys-neutral-text-support;
}

.productTags {
  margin-top: ste.$dimension-050m;
  display: flex;
  gap: 4px;
}

.productTag {
  position: relative;
  z-index: 1;
  width: fit-content;
}
