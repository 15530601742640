@use '@sbercloud/figma-tokens-web/build/scss/components/styles-tokens-element'
  as ste;

.formWithNotice {
  position: relative;
  box-sizing: border-box;
}

.formWrapper {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 56px 32px;
  background: ste.$sys-neutral-background1-level;

  &[data-layout-type='tablet'] {
    padding: 40px 32px;
  }

  &[data-layout-type='mobile'] {
    padding: 32px 16px;
  }

  &[data-modal] {
    padding: 0 0 ste.$space-modal-footer-bottom;
    background: transparent;

    &[data-layout-type='mobile'] {
      padding: 0;
    }
  }
}

.form {
  box-sizing: border-box;
  max-width: 680px;

  &[data-layout-type='mobile'] {
    max-width: 100%;
  }
}

.header {
  box-sizing: border-box;
  margin-bottom: 24px;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 12px;
  color: ste.$sys-neutral-text-main;
}

.inner {
  box-sizing: border-box;
  width: 100%;
  padding: 56px 0;
  background: ste.$sys-neutral-background;

  &[data-layout-type='tablet'] {
    padding: 40px 0;
  }

  &[data-layout-type='mobile'] {
    padding: 32px 0;
  }
}

.wrapper {
  box-sizing: border-box;
  position: relative;
  width: calc(100% - 120px);
  max-width: 1216px;
  margin: 0 auto;

  &[data-layout-type='tablet'] {
    max-width: 100%;
    width: 100%;
    padding: 0 32px;
  }

  &[data-layout-type='mobile'] {
    max-width: 100%;
    width: 100%;
    padding: 0 16px;
  }
}

.input,
.textarea {
  box-sizing: border-box;
  margin-bottom: 16px;
}

.double {
  box-sizing: border-box;
  margin-bottom: 16px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;

  &[data-layout='mobile'] {
    grid-template-columns: 1fr;
  }

  .input {
    margin: 0;
  }
}

.checkbox {
  box-sizing: border-box;
  margin-bottom: 16px;
}
