@use 'src/assets/scss/core/variables' as var;
@use 'src/assets/scss/core/mixins' as mix;

.root {
  @include mix.media(
    (
      1280: (
        width: calc(100% - 50px),
      ),
      md: (
        flex-direction: column,
        padding: 16px,
        gap: 16px,
        border-radius: 24px,
        align-items: first baseline,
        width: calc(100% - 80px),
      ),
      sm: (
        width: calc(100% - 48px),
        align-items: center,
      ),
    )
  );

  position: fixed;
  z-index: 20;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);

  display: flex;
  align-items: center;
  justify-content: space-between;

  box-sizing: border-box;
  width: calc(100% - 80px);
  max-width: 1214px;
  margin: 0 0 24px;
  padding: 8px;
  padding-left: 32px;

  text-size-adjust: 100%;

  background-color: rgba(#212830, 0.9);
  border-radius: 100px;

  :global(.c-button_primary) {
    @include mix.media(
      (
        1280: (
          font-size: mix.vw(18),
          line-height: mix.vw(26),
          padding: mix.vw(15) mix.vw(24),
        ),
        md: (
          font-size: 16px,
          line-height: 24px,
          padding: 10px 32px,
        ),
        sm: (
          padding: 12px 32px,
          min-width: 100%,
        ),
      )
    );

    flex: none;

    min-width: fit-content;
    padding: 15px 24px;

    font-family: var.$secondary-font;
    font-size: 18px;
    line-height: 26px;

    border: none;
    border-radius: 100px;
  }
}

.link {
  text-decoration-line: underline;
}

.text {
  @include mix.media(
    (
      1280: (
        font-size: mix.vw(14),
        line-height: mix.vw(20),
      ),
      md: (
        font-size: 14px,
        line-height: 20px,
        padding-left: 0,
      ),
    )
  );

  position: relative;

  display: block;

  width: 100%;
  max-width: 940px;

  font-family: var.$secondary-font;
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  line-height: 20px;
  color: var.$page-background;
  text-align: left;
  letter-spacing: 0.14px;
}
