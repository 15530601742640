@use '@sbercloud/figma-tokens-web/build/scss/components/styles-tokens-element'
  as ste;

@use 'src/assets/scss/core/mixins' as mix;

.footer {
  @include mix.media(
    (
      1023: (
        padding: 40px 0,
      ),
    )
  );

  &Wrapper {
    display: flex;
    flex-direction: column;
    gap: 40px;

    @include mix.media(
      (
        1023: (
          gap: 32px,
        ),
      )
    );
  }

  &Top {
    display: flex;
    align-items: center;
    gap: 24px;

    &Wrapper {
      display: flex;
      flex-direction: column;
    }
  }

  &Logo {
    width: 129px;
    height: 24px;

    &Link {
      height: 24px;
    }
  }

  &Body {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 32px;

    @include mix.media(
      (
        md: (
          grid-template-columns: repeat(2, 1fr),
        ),
        480: (
          grid-template-columns: 100%,
        ),
      )
    );
  }

  &Block {
    display: flex;
    flex-direction: column;
    gap: 32px;
  }

  &Title {
    margin-bottom: 16px !important;
  }

  &Links {
    margin: 0;
    padding: 0;

    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  &Link {
    text-decoration: none;

    &Wrapper {
      list-style-type: none;
    }

    &Text {
      color: ste.$sys-neutral-text-support;
    }

    &:hover {
      .footerLinkText {
        color: ste.$sys-neutral-text-main;
      }
    }
  }

  &Bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include mix.media(
      (
        1023: (
          flex-direction: column,
          justify-content: start,
          align-items: start,
          gap: 16px,
        ),
      )
    );

    &Item {
      &:nth-child(1) {
        @include mix.media(
          (
            1023: (
              order: 3,
            ),
          )
        );
      }

      &:nth-child(2) {
        @include mix.media(
          (
            1023: (
              order: 1,
            ),
          )
        );
      }

      &:nth-child(3) {
        @include mix.media(
          (
            1023: (
              order: 2,
            ),
          )
        );
      }
    }

    &Links {
      display: flex;
      flex-wrap: wrap;
      gap: 16px;
    }

    .footerBottomSocialButton {
      color: ste.$sys-neutral-accent-default;
      border: none;

      &:hover {
        color: ste.$sys-neutral-text-main;
      }
    }
  }

  &Copyright {
    color: ste.$sys-neutral-text-support;
  }
}

.blockGrid {
  display: grid;
  grid-template-columns: 280px 1fr;
  gap: 45px;

  @include mix.media(
    (
      1280: (
        grid-template-columns: mix.vw(280) 1fr,
        gap: mix.vw(45),
      ),
      1052: (
        grid-template-columns: 100%,
        gap: 32px,
      ),
    )
  );
}

.footerSubscribe {
  margin-top: auto;
  display: flex;
  flex-direction: column;
  gap: 24px;

  @include mix.media(
    (
      1280: (
        gap: mix.vw(24),
      ),
      1052: (
        gap: 24px,
      ),
    )
  );

  &Description {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &Text {
    color: ste.$sys-neutral-text-support;
  }
}
