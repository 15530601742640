@use '@sbercloud/figma-tokens-web/build/scss/components/styles-tokens-element'
  as ste;

.root {
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;
  padding: 40px;
  justify-content: center;

  background: ste.$sys-neutral-background1-level;

  &[data-layout-type='mobile'] {
    position: relative;
  }
}
