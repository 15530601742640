.root {
  position: fixed;
  z-index: 30;
  top: 0;
  left: 0;

  overflow: auto;

  width: 100%;
  height: 100%;
  min-height: 100vh;
}

.overlay {
  cursor: pointer;

  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  background: rgb(0 0 0 / 80%);
}
