@use '@sbercloud/figma-tokens-web/build/scss/components/styles-tokens-promoTag';

$appearances: 'neutral', 'primary', 'red', 'orange', 'yellow', 'green', 'blue',
  'violet', 'pink';
$colors: 'accent', 'decor';

$text-color: (
  accent: 'on-accent',
  decor: 'text-main',
);

.wrapper {
  @each $color in $colors {
    @each $appearance in $appearances {
      &[data-appearance='#{$appearance}'] {
        .tooltip {
          padding-right: styles-tokens-promoTag.$dimension-050m;

          $text: styles-tokens-promoTag.simple-var($text-color, $color);

          color: styles-tokens-promoTag.simple-var(
            styles-tokens-promoTag.$theme-variables,
            'sys',
            $appearance,
            $text
          );
        }
      }
    }
  }
}
