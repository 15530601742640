@use 'src/assets/scss/core/mixins' as mix;
@use 'src/assets/scss/core/variables' as var;
@use '@sbercloud/figma-tokens-web/build/scss/components/styles-tokens-element'
  as ste;

.field {
  @include mix.media(
    (
      1269: (
        max-width: 100%,
      ),
      md: (
        padding: 4px 112px 4px 38px,
      ),
    )
  );

  font-size: 14px;
  line-height: 20px;
  font-family: 'SB Sans Text';

  box-sizing: border-box;
  width: 100%;
  max-width: 576px;
  padding: 6px 114px 6px 38px;

  background: var.$gray-0;
  border: 1px solid var.$green-main-100;
  min-height: 40px;
  border-radius: 0;

  &::placeholder {
    @include mix.TextB2Regular;
    color: var.$graphite-50;
  }

  &:focus-visible {
    outline: 2px solid ste.$sys-primary-decor-activated;
  }
}

.separate {
  display: block;
  width: 1px;
  height: 32px;
  background: ste.$sys-neutral-decor-default;
  margin: 0 8px;
}

.close {
  &Icon {
    cursor: pointer;
    width: 20px;
    height: 20px;

    color: #a6a6a6;

    transition: color 0.3s ease-in-out;

    &:hover {
      color: var.$green-main-100;
    }
  }
}

.searchWrapper {
  display: flex;
}

.inputWrapper {
  position: relative;
  max-width: 576px;
  width: 100%;
  position: relative;

  @include mix.media(
    (
      1269: (
        max-width: 100%,
      ),
    )
  );
}

.searchActions {
  position: absolute;
  top: 50%;
  right: 4px;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
}

.buttonClose {
  margin-left: 16px;
}

.buttonClear {
  cursor: pointer;
  color: ste.$sys-neutral-text-light;
}

.closeIconWrapper {
  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 97px;
    display: block;
    width: 1px;
    height: 100%;
    background: #000;
  }
}

.iconSearch {
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  color: ste.$sys-neutral-text-light;
}
