@use 'src/assets/scss/core/variables' as var;

.root {
  margin: auto;
}

.loader {
  position: relative;
  width: 82px;
  height: 82px;
  margin: 0 auto;

  &::after {
    content: '';

    position: absolute;
    top: 0;
    left: 0;

    display: block;

    width: 82px;
    height: 82px;

    border-color: rgb(2 2 2 / 0%) var.$main-color var.$main-color
      rgb(0 0 0 / 0%);
    border-style: solid;
    border-width: 4px;
    border-radius: 50%;

    animation: anti-rotate-animation 0.85s linear 0s infinite;
  }

  &.white {
    &::after {
      border-color: rgb(255 255 255 / 0%) #fff #fff rgb(255 255 255 / 0%);
    }
  }
}

@keyframes rotate-animation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes anti-rotate-animation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(-360deg);
  }
}
