@use 'src/assets/scss/core/variables' as var;
@use 'src/assets/scss/core/mixins' as mix;

.title {
  @include mix.media(
    (
      1280: (
        margin-top: mix.vw(15),
        font-size: mix.vw(16),
        line-height: mix.vw(20),
      ),
      md: (
        font-size: 16px,
        line-height: 20px,
        margin-top: 15px,
      ),
    )
  );

  display: block;

  margin-top: 15px;

  font-size: 16px;
  line-height: 20px;
  color: red;
  text-align: center;
}
