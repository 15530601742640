@use 'src/assets/scss/core/mixins' as mix;
@use 'src/assets/scss/core/variables' as var;
@use '@sbercloud/figma-tokens-web/build/scss/components/styles-tokens-element'
  as ste;

.buttonsWrapper {
  flex-shrink: 0;
  margin-left: auto;
  display: flex;
  align-items: center;

  @include mix.media(
    (
      1240: (
        margin-left: 24px,
      ),
      1269: (
        margin-left: 0,
        display: flex,
        align-items: center,
        gap: 8px,
      ),
    )
  );

  .consoleButtonReg {
    margin-left: 8px;
    background: ste.$sys-graphite-text-main;
  }
}

.consoleButton {
  margin-left: 16px;

  @include mix.media(
    (
      1269: (
        margin-left: 0,
      ),
    )
  );

  &.mobileHidden {
    @include mix.media(
      (
        1269: (
          display: none,
        ),
      )
    );
  }
}

.authAvatar {
  margin-left: 12px;
  cursor: pointer;
}

.mobileButtonWrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;

  .consoleButtonReg {
    background: ste.$sys-graphite-text-main;
  }
}
