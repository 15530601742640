.root {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 16px;
  margin-bottom: 16px;
}

.full {
  grid-column: span 2;
}

.half {
  grid-column: span 1;
}
