@use 'src/assets/scss/core/variables' as var;
@use 'src/assets/scss/core/mixins' as mix;

.root {
  cursor: pointer;
  user-select: none;
  display: block;
  min-width: 197px;
}

.link {
  min-width: auto;

  font-size: 16px;
  font-weight: 600;
  line-height: 21px;
  color: var.$green-color;

  transition: color 0.3s ease-in-out;

  &.disabled {
    border: none;

    &:hover {
      border: none;
    }
  }

  &:hover {
    color: var.$main-color;
  }
}

.primary {
  display: flex;
  align-items: center;
  justify-content: center;

  box-sizing: border-box;

  font-weight: 400;
  color: var.$main-color;

  background: var.$green-color;
  border: 1px solid var.$green-color;
  border-radius: 100px;

  transition: color 0.3s ease-in-out, background 0.3s ease-in-out,
    border-color 0.3s ease-in-out;

  &:hover,
  &:active {
    color: #fff;
    background: #343f48;
    border-color: var.$main-color;
    box-shadow: 0 13px 60px rgb(255 255 255 / 17%);
  }
}

.outline {
  display: flex;
  align-items: center;
  justify-content: center;

  box-sizing: border-box;

  font-weight: 400;
  color: #fff;

  border: 1px solid var.$green-color;
  border-radius: 100px;

  transition: color 0.3s ease-in-out, background 0.3s ease-in-out,
    border-color 0.3s ease-in-out;

  &:hover,
  &:active {
    color: #212830;
    background: #07e897;
    border-color: #07e897;
  }

  &.loading {
    &:hover {
      background: transparent !important;
    }
  }
}

.default {
  @include mix.media(
    (
      md: (
        font-size: 16px,
        line-height: 24px,
      ),
    )
  );

  padding: 10px;
  font-size: 18px;
  line-height: 26px;
}

.large {
  @include mix.media(
    (
      md: (
        font-size: 20px,
        line-height: 28px,
      ),
    )
  );

  padding: 16px;
  font-size: 24px;
  line-height: 32px;
}

.disabled {
  color: #424242;
  text-shadow: none;

  background: transparent;
  border: 1px solid #424242;
  box-shadow: none;

  &:hover {
    cursor: not-allowed;

    color: #424242;
    text-shadow: none;

    background: transparent;
    border: 1px solid #424242;
    box-shadow: none;
  }
}

.loading {
  cursor: not-allowed;

  &::after {
    content: ' ';

    display: block;

    width: 30px;
    height: 30px;
    margin: 8px;

    border: 3px solid #fff;
    border-color: #fff transparent;
    border-radius: 50%;

    animation: spinner 1.2s linear infinite;
  }

  @keyframes spinner {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
}
